
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { authHeader } from '../../../helpers/fakebackend/auth-header';
import DatePicker from "vue2-datepicker";

import {
  required 
} from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
  // let state = {
  //     disabledDates: {
  //         to: new Date(2021, 0, 5), 
  //     }
  // }
export default {
  page: {
    title: "Discount & Coupons",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    DatePicker
    
  },
  data() {
    return {
      title: "Discounts",
      items: [
        {
          text: "Discounts",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


      nowDate: new Date().toISOString().slice(0,10),
      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      discountTypeArr : [
                {'disType':1,'disTypeName':'Net Discount'},
                {'disType':2,'disTypeName':'Percent Discount'}
      ],
      discountType:"",
      startTime:"12:00",
      endTime:"23:59",
      typeOfCust:"1",
      daterange:[],
      disabled:true,
      restaurantID:"",
      restaurants:[],
      companyID:0,
      discountInRs:"",
      discountPercent:"",
      usageTypeArr:[
        {'usageTypeID':1,'usageType':'Single User Single Time'},
        {'usageTypeID':2,'usageType':'Single User Multiple Time'},
        {'usageTypeID':3,'usageType':'Multiple User Single Time'},
        {'usageTypeID':4,'usageType':'Multiple User Once a Day'}
      ],
      usageType:'',
      maxDiscountCapping:0,
      couponCode:'',
      minOrderValue:0,
      couponDescription:"",
      couponTitle:"",
      menuTabArr : [],
      menuTabType :"",


         // table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: 'multi',
      selected: [],
  
      

      fields: [
        {
          key: "selected",
          
          sortable: false,
        },
        
        
        {
          key: "branchName",
          sortable: true,
        },
        {
          key: "address",
          
          sortable: true,
        },
        
      ],
      
      
 
    };
  },

    validations: {
      restaurantID:{
        required,
      },
      selected:{
        required,
      },
      discountType: {
        required,
      },
      daterange: {
        required,
      },
      couponCode:{
        required,
      }
     
  
  },
  methods:{
    changeDiscountType(){
        this.couponDescription = '';
        this.couponTitle = '';
        this.discountInRs='';
        this.discountPercent= '';
        this.maxDiscountCapping = '';
        this.minOrderValue = 0;
    },
   changeDesc(){
     
     var descText = "";
     var titleText = "";
     if(this.discountType.disType==1){
          if(this.discountInRs>0){

            descText = descText+'Use code '+this.couponCode+' & get ₹'+this.discountInRs+' discount';
            titleText = titleText+'Get ₹'+this.discountInRs+' discount using this coupon code';
            
          }
     }else{
          if(this.discountPercent>0){

            descText = descText+'Use code '+this.couponCode+' & get '+this.discountPercent+'% discount';
            titleText = titleText+'Get '+this.discountPercent+'% discount using this coupon code';
            
          }
          
          if(this.maxDiscountCapping>0){

            descText = descText+' upto ₹'+this.maxDiscountCapping;
            
          }
     }
     
     if(this.minOrderValue>0){

       descText = descText+' on orders above ₹'+this.minOrderValue;
       
     }
     this.couponDescription = descText;
     this.couponTitle = titleText;
     
   },

 openModal(){
      //alert();
       this.$root.$emit("bv::show::modal", "modalBranches");
       this.readRestaurantBranchData();
    },


    onRowSelected(items) {
        this.selected = items;
       // console.log(this.selected);
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },


  readRestaurantBranchData(){
  let restID = this.restaurantID.restID;
        this.axios
      .post(this.$loggedRole+"/getRestaurantBranchByRestID",
        {'restID':restID,'restBranchID':this.$storageData.profile.restBranchID,'empTypeID':this.$storageData.profile.empTypeID}
      )
      .then((response) => {
        //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        
      });
  },
onChangeRestBrand(){
    this.disabled = false; // all input fields are unabled
      ///// get branches table data ///////
    this.totalRows = this.items.length;

    },

getRestaurantList(){
  var companyID = this.companyID;
  if(this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 11)
  {
    companyID = this.$storageData.profile.companyID;
  }

  this.axios.post(this.$loggedRole+"/getRestaurantList", {
    companyID: companyID,
    'restID': this.$storageData.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restID : 0,
    'restBranchID': this.$storageData.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restBranchID : 0,
    'empTypeID': this.$storageData.profile.empTypeID
  })
  .then((result) => {
    this.restaurants = result.data.data;      
  });
},

getMenuTabType(){
      this.axios.post(this.$loggedRole+"/getMenuTabType")
      .then((result)=>{
        this.menuTabArr = result.data.data;      
    });
},

  submitForm(e){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
          let formData = new FormData();  
         
            formData.append('discountType', this.discountType.disType);
            formData.append('restaurantID', this.restaurantID.restID);
            formData.append('validityPeriod', this.daterange);
            formData.append('validityTimeStart', this.startTime);
            formData.append('validityTimeEnd',this.endTime);
            formData.append('discountInRs', this.discountInRs);
            formData.append('discountPercent', this.discountPercent); 
            formData.append('minOrderValue', this.minOrderValue); 
            formData.append('typeOfCustomer',this.typeOfCust);
            formData.append('maxDiscountCapping',this.maxDiscountCapping);
            formData.append('couponCode',this.couponCode);
            formData.append('restBranchIDs',JSON.stringify(this.selected));
            formData.append('usageRestrictions',this.usageTypeArr.usageTypeID);
            formData.append('couponTitle',this.couponTitle);
            formData.append('couponDescription',this.couponDescription);
            formData.append('menuTabType',this.menuTabType.enum);
            formData.append('createdBy', this.$storageData.profile.pid);
            formData.append('loginTypeID',this.$storageData.login_type);


      e.preventDefault();
      this.axios.post(this.$loggedRole+"/addDiscountCoupons",  formData, {
          headers: authHeader()
        })
      .then((result)=>{
        this.status = result.data.data;
              this.showDismissibleAlert = true;
              this.loading = false;

              setTimeout(() => {
                  this.$router.push({name: 'discountTable'}); 
              },2000);

      })
     .catch((error)=> {
       this.loading = false;
                        //console.error(error.response);
                        alert(error.response.data.data);
                    });
      }  
    },
onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

mounted(){
    this.getRestaurantList();
    this.getMenuTabType();

},

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
  <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
          Coupons added Successfully!
    </b-alert>
  </div>

    <PageHeader :title="title" :items="items" />
<form @submit.prevent="submitForm" method="post">

        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                                <label class="form-label" for="formrow-restType-input">Discount Type*</label>
                                    <multiselect v-model="discountType" :options="discountTypeArr" :show-labels="false" @input="changeDiscountType();" label="disTypeName" track-by="disType"
                                     :class="{
                                    'is-invalid': submitted && $v.discountType.$error,
                                }"></multiselect>
                                 <div
                                v-if="submitted && $v.discountType.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.discountType.required"
                                    >This value is required.</span
                                >
                                </div>
                        </div>
                        <div class="col-md-3" v-if="discountType.disType==1">
                                 <label class="form-label" for="formrow-price-input">Discount *</label>
                                    <div class="input-group"><div class="input-group-text">₹</div> 
                                    
                                        <input type="number" @input="changeDesc();" class="form-control" id="formrow-price-input"  
                                        v-model="discountInRs">
                                          
                                    </div>
                        </div>
                        <div class="col-md-3" v-if="discountType.disType==2">
                                 <label class="form-label" for="formrow-price-input">Discount *</label>
                                    <div class="input-group">
                                          <input type="number"  @input="changeDesc();" class="form-control" :max="100" id="formrow-price-input"  
                                        v-model="discountPercent">
                                        <div class="input-group-text">%</div> 
                                    
                                      
                                          
                                    </div>
                        </div>
                         <div class="col-md-3" v-if="discountType.disType==2">
                                 <label class="form-label" for="formrow-price-input">Max Discount Capping </label>
                                <div class="input-group"><div class="input-group-text">₹</div> 
                                    <input type="number" @input="changeDesc();" :min="0"  class="form-control" id="formrow-price-input"  
                                        v-model="maxDiscountCapping">
                                </div>
                                      
                        </div>
                        <div class="col-md-3" v-if="discountType.disType==1 || discountType.disType==2">
                            <label class="form-label" for="formrow-minOrderQtny-input">Min Order Value *</label>
                            <div class="input-group"><div class="input-group-text">₹</div> 
                                <input type="number" @input="changeDesc();" :min="0" class="form-control" id="formrow-minOrderQtny-input"  v-model="minOrderValue">
                            </div>
                        </div>
                    </div>
                
                  <div class="row mt-3">
                        <div class="form-group mb-3 col-md-6">
                                    <label>Validity Period*</label>
                                    <br />
                                    <date-picker v-model="daterange" range append-to-body lang="en" confirm :class="{
                                    'is-invalid': submitted && $v.daterange.$error,
                                }"></date-picker>
                                <div
                                v-if="submitted && $v.daterange.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.daterange.required"
                                    >This value is required.</span
                                >
                                </div>
                        </div>
                       
                       <div class="mb-3 col-md-6 row">
                                    <label>Validity Time</label>
                          <div class="mb-3 col-md-5">
                              <!-- <b-form-timepicker id="timepicker-sm" size="sm" locale="en" class="mb-2"></b-form-timepicker> -->
                              <input class="form-control" type="time" value="13:45:00"  v-model="startTime">
                          </div>
                         
                          <div class="mb-3 col-md-5">
                             
                              <input class="form-control" type="time" value="13:45:00"  v-model="endTime">
                          </div>
                        </div>

                       
                   </div>
                   <div class="row">
                     <div class="col-md-6">
                       <div class="row">
                     <label> Type Of Customer </label>
                      <div class="col-md-1">
                        <b-form-radio
                            v-model="typeOfCust"
                            class="custom-radio mb-3"
                            value="1"
                            plain
                            >All</b-form-radio
                          >
                      
                        </div>
                        <div class="col-md-6">
                         <b-form-radio
                            v-model="typeOfCust"
                            class="mb-3"
                            value="2"
                            plain
                            >First time Customer</b-form-radio
                          >
                        </div>
                        </div>
                        </div>
                         <div class="col-md-6">
                                <label class="form-label" for="formrow-restType-input">Menu Type</label>
                                    <multiselect v-model="menuTabType" :options="menuTabArr" :show-labels="false"  label="menuAccessName" track-by="enum"
                                     ></multiselect>
                                
                        </div>
                     </div>
                    <div class="row  mt-3">
                      
                      <div class="col-md-6">
                        <label> Coupon Code *</label>
                        <input type="text" @input="changeDesc();" class="form-control" id="formrow-couponCode-input" 
                             placeholder="Eg: GET20"  v-model="couponCode" :class="{
                                    'is-invalid': submitted && $v.couponCode.$error,
                                }">
                        <div
                          v-if="submitted && $v.couponCode.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.couponCode.required"
                              >This value is required.</span
                          >
                          </div>
                      </div>

                      <div class="col-md-6">
                        <label> Usage Restrictions </label>
                        <multiselect v-model="usageType" :options="usageTypeArr" track-by="usageTypeID" label="usageType"
                                > 
                        </multiselect>
                      </div>
                    </div>
                    <h6 class="mt-3 mb-3"> Applicable to outlets </h6>
                      <div class="row">
                  <div class="col-md-6 mb-3">
                           <label>Select Restaurant Brand* </label>
                                <multiselect v-model="restaurantID" :options="restaurants" track-by="restID" label="name" @input="onChangeRestBrand();"
                                :class="{
                                    'is-invalid': submitted && $v.restaurantID.$error,
                                }" > 
                                </multiselect>
                                <div
                                v-if="submitted && $v.restaurantID.$error"
                                class="invalid-feedback"
                                >
                                <span v-if="!$v.restaurantID.required"
                                    >This value is required.</span
                                >
                                </div>
                      </div>
                    <div class="mb-3 col-md-6 row">
                      <div class="col-md-6">
                          <label class="form-label" for="formrow-firstname-input">Select Restaurant Branches *</label><br>
                         <button type="button" class="btn btn-themeOrange  w-md waves-effect waves-light " :disabled="disabled" @click="openModal();">Restaurant Branches
                           </button> 
                      </div>
                        
                    
                                    <p class="col-md-6" :class="{
                                    'is-invalid': submitted && $v.selected.$error,
                                      }">
                                      Selected branches:<br>
                                      {{ selected.length }}
                                     
                                    </p>
                                      <div
                                          v-if="submitted && $v.selected.$error"
                                          class="invalid-feedback"
                                          >
                                          <span v-if="!$v.selected.required"
                                              >This value is required.</span
                                          >
                                          </div>
        
                    </div>
                    </div>
                  
                          <b-modal
                        id="modalBranches"
                        title="Restuarant Branches"
                        title-class="font-18"
                        
                      size="lg"
                      >
                                      <!-- Table -->
                    <div class="table-responsive mb-0">
                      <h6>Restaurant Brand: {{this.restaurantID.name}}</h6>
                          <p>
                          <b-button size="sm" class="me-2" @click="selectAllRows">Select all</b-button>
                          <b-button size="sm" @click="clearSelected">Clear selected</b-button>
                        
                        </p>
                      <b-table
                      
                        striped hover
                        outlined
                        bordered
                      
                        :items="tableData"
                        :fields="fields"
                        :select-mode="selectMode"
                        ref="selectableTable"
                        selectable
                        @row-selected="onRowSelected"
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        
                        
                      >
                    
                    
              <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true"><i class="far fa-check-square"></i></span>
                  <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                  <span aria-hidden="true"><i class="far fa-square"></i></span>
                  <span class="sr-only">Not selected</span>
                </template>
              </template>
            
    
              </b-table>

     
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

              </b-modal>
              <div class="row">
                  <div class="col-md-6">
                       <label class="form-label" for="formrow-coupontitle-input">Title </label>
                               
                                <textarea
                                v-model="couponTitle"
                                  
                                  class="form-control"
                                  name="textarea"
                                  rows="1"
                                ></textarea>
                            
                              <label class="form-label" for="formrow-couponDescription-input">Description </label>
                            
                              <textarea
                                v-model="couponDescription"
                                  
                                  class="form-control"
                                  name="textarea"
                                  rows="3"
                                ></textarea>
                               

                  </div>
                  <div class="col-md-6" v-if="couponCode">
                       <div class="container" style="border: 1px solid #e4e4e4;border-radius: 5px;">
                          <div class="couponsSectionPop" style="margin: 20px;">
                              <div class="row">
                                  <div class="col-md-4 col-lg-4 col-xl-4 couponDiv align-center">
                                      {{couponCode}}
                                  </div>
                                  <div class="col-md-8 col-lg-8 col-xl-8 applyDiv align-right">
                                      <a href="#" class="customAnch">Apply</a>
                                  </div>
                              </div>
                              <!-- / row -->
                              <div class="mainContent">
                                  <div>
                                      <h4 class="descTitle">{{couponTitle}}</h4>
                                      <p class="descDescription">{{couponDescription}}</p>
                                  </div>
                              </div>

                              <hr>
                              
                          </div>
                          </div>
                  </div>
              </div>
                   

                    <div class =" row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                    <button type="submit" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                  </div>

                </div>
               
            </div>
        </div>



</form>


  

</Layout>
</template>

<style scoped>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}


.availOffers
{
    background: #f3f3f3;
}
.availOffersDiv{
    padding: 10px 0;
    margin-bottom: 10px;
}
.availOffHead
{
    margin-bottom: 0px;
}
.couponDiv{
    background: #fef1f0;
    padding: 10px 0;
    border: 2px dashed #F3766A;
    border-radius: 5px;
    text-align:center;
}
.applyDiv{
    line-height: 3;
    color: #F3766A;
    text-align: right;
}
.mainContent{
    margin-top: 15px;
}
.descTitle
{
    font-size: 16px;
}
.descDescription
{
    font-size: 12px;
    color: #777;
}
.availOffersSection
{
    overflow-y: scroll;
    max-height: 430px;
}
.availOffersSection::-webkit-scrollbar 
{
    display: none;
}
</style>


